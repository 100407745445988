<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        style="z-index: 999;"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-model="data"
                :label="label"
                :dense="dense"
                :outlined="outlined"
                readonly
                v-on="on"
                hide-details
                clearable
            ></v-text-field>
        </template>
        <v-date-picker v-model="data" @input="menu = false" no-title scrollable>
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'TimePicker',
    props: [
        'model',
        'label',
        'dense',
        'outlined',
        'clearable',
    ],
    data() {
        return {
            menu: false,
        };
    },
    created(){
    },
    computed:{
        data: {
            get() {
                return this.model;
            },
            set(value) {
                this.$emit('update:model', value);
            }
        },
    },
    watch: {
        data: function () {
            this.$emit('input', this.data);
        }
    }
}
</script>
