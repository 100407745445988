<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentResale.name === undefined">
            <h2 class="title is-3">Создать партнера</h2>
          </div>
          <div class="column" v-if="currentResale.name !== undefined">
            <h2 class="title is-3">Изменить партнера: {{ currentResaleName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentResale.name"></b-input>
            </b-field>
            <b-field label="ID партнера в кейтаро(через запятую)">
              <b-input v-model="currentResale.campaign_ids"></b-input>
            </b-field>
            <b-field label="Процент комиссии(вместо запятой ставьте точку)">
              <b-input v-model="currentResale.total_percent"></b-input>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentResale.id == null" @click="createResale()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentResale.id != null" @click="updateResale()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'ResaleDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      currentResaleName: '', // Копия имени шаблона
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.currentResaleName = this.currentResale.name;
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_RESALE_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_RESALE_DETAILS_MODAL_OPENED;
    },
    currentResale: {
      get() {
        return this.$store.getters.GET_CURRENT_RESALE;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_RESALE", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_RESALE", { });
      this.$store.dispatch('RESALE_DETAILS_MODAL', false);
    },
    createResale(){
      this.$store.dispatch('CREATE_RESALE', this.currentResale)
        .then(() => {
          this.$toast.success("Партнер успешно добавлен");
          this.$store.dispatch("CURRENT_RESALE", { });
          this.$store.dispatch("GET_RESALES");
          this.$store.dispatch('RESALE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении партнера");
        });
    },
    updateResale(){
      this.$store.dispatch('UPDATE_CURRENT_RESALE', this.currentResale)
        .then(() => {
          this.$toast.success("Партнер успешно изменен");
          this.$store.dispatch("CURRENT_RESALE", { });
          this.$store.dispatch("GET_RESALES");
          this.$store.dispatch('RESALE_DETAILS_MODAL', false);
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении партнера");
        });
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    }
  },
};
</script>
