<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список партнеров ({{ resales.length }})</h2>
      </div>
      <div class="column">
        <TimePicker
            :model.sync="params.registerDateFrom"
            label="Дата постбеков: от"
            :outlined="true"
            :dense="true"
            @click:clear="onClear"
            @input="filterResale"
        ></TimePicker>
      </div>
      <div class="column">
        <TimePicker
            :model.sync="params.registerDateTo"
            label="Дата постбеков: до"
            :outlined="true"
            :dense="true"
            @click:clear="onClear"
            @input="filterResale"
        ></TimePicker>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createResale()"
        >Добавить партнера</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="resales"
      class="users-table"
    >
      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Процент" v-slot="props">
        <span>{{ props.row.total_percent }}</span>
      </b-table-column>

      <b-table-column label="Выручка общая" v-slot="props">
        <span>{{ props.row.parent_sum }}</span>
      </b-table-column>

      <b-table-column label="Выручка партнера" v-slot="props">
        <span>{{ props.row.partner_sum }}</span>
      </b-table-column>

      <b-table-column label="Итого" v-slot="props">
        <span>{{ props.row.revenue }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">

        <div>
          <b-tooltip label="Редактировать">
            <b-button class="edit_icon" @click="updateResale(props.row.id)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              class="delete_icon"
              @click="deleteResale(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>

      </b-table-column>
    </b-table>

    <OverlayLoader :show="isLoading"></OverlayLoader>
    <ResaleDetailsModal></ResaleDetailsModal>
    <ConfirmDeletionModal></ConfirmDeletionModal>
  </div>
</template>

<script>
import ResaleDetailsModal from '@/app/client/resale/shared/modals/resaleDetailsModal.vue';
import ConfirmDeletionModal from '@/app/client/resale/shared/modals/confirmDeletionResaleModal.vue';
import TimePicker from "@/app/shared/components/form-inputs/TimePicker.vue";
import OverlayLoader from "@/app/shared/components/app-loader/Loader";
import moment from 'moment';

export default {
  name: "ResalesList",
  components: {
    ResaleDetailsModal,
    ConfirmDeletionModal,
    TimePicker,
    OverlayLoader
  },
  data() {
    return {
      params: {registerDateFrom: moment().format("YYYY-MM-DD"), registerDateTo: moment().format("YYYY-MM-DD")},
    };
  },
  created() {
    this.filterResale();
  },
  computed: {
    resales() {
      return this.$store.getters.GET_RESALES;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_RESALES_LOADING;
      },
      set(value) {
        this.$store.commit("SET_RESALES_LOADING", value);
      },
    },
  },
  methods: {
    createResale() {
      this.$store.dispatch("RESALE_DETAILS_MODAL", true);
    },
    updateResale(resale_id) {
      this.$store.dispatch("GET_RESALE_BY_ID", resale_id).then(() => {
        this.$store.dispatch("RESALE_DETAILS_MODAL", true);
      }).catch(() => {
        this.$toast.error("Ошибка при получении партнера");
      });
    },
    deleteResale(resale) {
      this.$store.dispatch("CURRENT_RESALE", resale);
      this.$store.dispatch("CONFIRM_DELETION_RESALE_MODAL", true);
    },
    onClear() {
      this.$store.dispatch("GET_RESALES",{});
    },
    filterResale() {
      this.$store.dispatch("GET_RESALES", this.params);
    },
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
